import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const author = data?.author?.childImageSharp?.gatsbyImageData

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={`About ${siteTitle}`} />
      <h1>About</h1>
      <p>The cloud makes computing easier. Learning it can be hard.</p>
      <p>
        I think one of the best ways to learn cloud computing is to get hands-on
        experience solving problems.
      </p>
      <p>
        This blog is going to provide the problems. Ridiculous problems. It's
        also going to provide the step-by-step solutions that you can follow to
        learn.
      </p>
      <blockquote>
        For a number of years now, work has been proceeding in order to bring
        perfection to the crudely conceived idea of computing that would not
        only supply inverse reactive current for use in unilateral phase
        detractors, but would also be capable of automatically synchonizing
        cardinal grammeters. Such an instrument is the cloud encabulator.
      </blockquote>
      <p>
        The encabulator is an inside joke in engineering. It's a fictional
        product solving fictional problems using technical words that pass for
        jargon, but are really just nonsense when put together. We're going to
        build real solutions here. No nonsense.
      </p>
      <p>Okay, some nonsense.</p>

      <h2>About the Author</h2>

      {author && (
        <GatsbyImage
          image={author}
          alt="Charles Entertainment Cheese"
          imgStyle={{
            borderRadius: `5%`,
          }}
        />
      )}

      <p>
        Derek Jamison, right, has been building on the cloud for over 10 years.
        He coined the term "migration therapist." He works for AWS (but all
        these opinions are his own).
      </p>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <a href="https://www.youracclaim.com/badges/5b1cdef5-8c25-43ea-a1cd-03f4aa4bc23d/public_url">
          <GatsbyImage
            image={data?.cert_cp?.childImageSharp?.gatsbyImageData}
            alt="Certified Cloud Practitioner"
          />
        </a>

        <a href="https://www.youracclaim.com/badges/8702d497-a78a-42e4-afb2-4acb07771509/public_url">
          <GatsbyImage
            image={data?.cert_saa?.childImageSharp?.gatsbyImageData}
            alt="Certified Solutions Architect Associate"
          />
        </a>

        <a href="https://www.youracclaim.com/badges/55b29ff0-9d11-4616-89c0-59da38ad0314/public_url">
          <GatsbyImage
            image={data?.cert_dev?.childImageSharp?.gatsbyImageData}
            alt="Certified Developer Associate"
          />
        </a>

        <a href="https://www.youracclaim.com/badges/9cb0c6f9-2716-4945-bef1-e1c62c27a91a/public_url">
          <GatsbyImage
            image={data?.cert_sys?.childImageSharp?.gatsbyImageData}
            alt="Certified Sysops Administrator Associate"
          />
        </a>
      </div>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <a href="https://www.youracclaim.com/badges/b453dd67-1bda-4301-be9e-3c3d2714bd61/public_url">
          <GatsbyImage
            image={data?.cert_sap?.childImageSharp?.gatsbyImageData}
            alt="Certified Solutions Architect Professional"
          />
        </a>

        <a href="https://www.youracclaim.com/badges/08976de3-1cc0-445c-8f36-4412d5990ce4/public_url">
          <GatsbyImage
            image={data?.cert_devp?.childImageSharp?.gatsbyImageData}
            alt="Certified DevOps Engineer Professional"
          />
        </a>
      </div>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <a href="https://www.youracclaim.com/badges/c5e1e46d-81b0-4146-ad29-21a2ca09029a/public_url">
          <GatsbyImage
            image={data?.cert_sec?.childImageSharp?.gatsbyImageData}
            alt="Certified Security Speciality"
          />
        </a>

        <a href="https://www.youracclaim.com/badges/0870d989-1fc7-459f-9ec3-a80cbcf56fd3/public_url">
          <GatsbyImage
            image={data?.cert_net?.childImageSharp?.gatsbyImageData}
            alt="Certified Advanced Networking Speciality"
          />
        </a>

        <a href="https://www.youracclaim.com/badges/f4e77baa-a719-468d-b4f4-28ef1c2d1eba/public_url">
          <GatsbyImage
            image={data?.cert_big?.childImageSharp?.gatsbyImageData}
            alt="Certified Big Data Speciality"
          />
        </a>

        <a href="https://www.youracclaim.com/badges/fef9e8da-5b49-4f7e-bcdc-41a5796993d0/public_url">
          <GatsbyImage
            image={data?.cert_ml?.childImageSharp?.gatsbyImageData}
            alt="Certified Machine Learning Speciality"
          />
        </a>

        <a href="https://www.youracclaim.com/badges/1cdb548a-0b9f-460b-a2c2-f62b223bbc1b/public_url">
          <GatsbyImage
            image={data?.cert_db?.childImageSharp?.gatsbyImageData}
            alt="Certified Database Speciality"
          />
        </a>
      </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    author: file(relativePath: { eq: "about/author.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 300
          height: 300
          quality: 95
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    cert_cp: file(relativePath: { eq: "about/certs/cp.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }
    cert_saa: file(relativePath: { eq: "about/certs/saa.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }
    cert_dev: file(relativePath: { eq: "about/certs/dev.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }
    cert_sys: file(relativePath: { eq: "about/certs/sys.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }

    cert_sap: file(relativePath: { eq: "about/certs/sap.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }

    cert_devp: file(relativePath: { eq: "about/certs/devp.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }

    cert_sec: file(relativePath: { eq: "about/certs/sec.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }

    cert_net: file(relativePath: { eq: "about/certs/net.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }

    cert_big: file(relativePath: { eq: "about/certs/big.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }

    cert_ml: file(relativePath: { eq: "about/certs/ml.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }

    cert_db: file(relativePath: { eq: "about/certs/db.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150, height: 150, quality: 100)
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`
